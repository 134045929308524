<div *ngIf="isCashier()" class="text-center p-2 bg-yellow-400 font-bold select-none text-[var(--primary-color)] uppercase">
    {{ 'navbar.cashier-mode' | translate }}
</div>
<header class="sm:fixed sm:left-0 sm:top-0 sm:w-full sm:z-10 relative sm:!px-0">
    <div class="container relative">
        <div class="w-full justify-between items-center flex sm:!flex sm:!justify-between">
            <div class="flex justify-between gap-20 sm:!hidden">
                <a [routerLink]="['/'] | localize" class="flex items-center">
                    <img src="/assets/images/logo.png" class="w-[113px]" alt="csakjegyek.hu">
                </a>
                <div>
                    <ul class="gap-10 flex" id="menu">
                        <li class="inline">
                            <a [routerLink]="['/fixtures' |  localize ]" class="uppercase font-bold text-sm">{{ 'navbar.fixtures' | translate }}</a>
                        </li>
                        <li class="inline">
                            <a [routerLink]="['/season-passes' |  localize ]" class="uppercase font-bold text-sm">{{ 'navbar.season-passes' | translate }}</a>
                        </li>
                        <li class="inline">
                            <a routerLink="/flex" class="uppercase font-bold text-sm">Flex</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="flex gap-[10px] sm:!hidden items-center">
                <app-login-or-register></app-login-or-register>
                <a [routerLink]="['/cart'] | localize"><app-cart-navbar></app-cart-navbar></a>
                <div class="relative flex items-center" #languageSelector>
                    <button (click)="showLanguageSelector = !showLanguageSelector">
                        <img src="/assets/icons/language.svg" alt="Locale">
                    </button>
                    <ul *ngIf="showLanguageSelector" class="flex flex-col absolute -right-[10px] top-[38px] z-[9] rounded-b-[10px] overflow-hidden px-4 bg-[var(--primary-color)] ">
                        <li 
                            class="cursor-pointer px-4 py-3 hover:font-black uppercase text-[rgba(255,255,255,.5)] hover:text-[rgba(255,255,255,1)]"
                            *ngFor="let language of availableLanguages; index as index" 
                            (click)="setLanguage(language.slug)" 
                            [ngClass]="{'font-black !text-[rgba(255,255,255,1)]' : isCurrentLanguage(language.slug), 'border-b border-white' : index < availableLanguages?.length! -1}" >
                            {{ language.name }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hidden sm:!flex sm:!justify-between sm:w-full">
                <div class="flex gap-[15px] justify-between items-center">
                    <app-login-or-register class="sm:hidden"></app-login-or-register>
                    <a [routerLink]="['/cart'] | localize "><app-cart-navbar [cartCountdownCounter]="false"></app-cart-navbar></a>
                </div>

                <a [routerLink]="['/'] | localize" class="flex items-center">
                    <img src="/assets/images/logo.png" class="w-[120px] absolute left-1/2 -translate-x-1/2" alt="csakjegyek.hu">
                </a>

                <div class="hamburger hamburger3" (click)="toggleMenu()" [ngClass]="{'opened' : common.showMobileMenu}">
                    <span class="bar1 bar-black"></span>
                    <span class="bar2 bar-black"></span>
                    <span class="bar3 bar-black"></span>
                </div>
            </div>
        </div>
        <app-profile-menu *ngIf="common.showProfileMenu"></app-profile-menu>
    </div>
</header>
<div *ngIf="common.showMobileMenu" class="fixed top-[56px] left-0 w-full h-[calc(100vh-56px)] z-20  backdrop-blur-sm">
    <div class="w-full h-full bg-[var(--primary-color)] pt-10 pb-32 flex flex-col justify-between">
        <ul class="gap-10 flex flex-col mb-12">
            <!-- GUEST -->
            <ng-container>
                <li
                    class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                    <a [routerLink]="['/fixtures'] | localize"
                        class="block uppercase text-xl font-bold text-white text-center">{{ 'navbar.fixtures' | translate }}</a>
                </li>

                <li
                    class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                    <a [routerLink]="['/season-passes'] | localize"
                        class="block uppercase text-xl font-bold text-white text-center">{{ 'navbar.season-passes' | translate }}</a>
                </li>

                <li
                    class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                    <a [routerLink]="['/app'] | localize" class="block uppercase text-xl font-bold text-white text-center">{{ 'navbar.app' | translate }}</a>
                </li>

                <li class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                    <a routerLink="/flex" class="block uppercase text-xl font-bold text-white text-center">Flex</a>
                </li>
            </ng-container>

            <!-- LOGGED IN -->
            <ng-container *ngIf="isUserLoggedIn() && !isCashier()">
                <li  class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                    <a [routerLink]="['/my-tickets'] | localize" class="block uppercase text-xl font-bold text-white text-center">{{ 'navbar.my-tickets' | translate }}</a>
                </li>
                <li class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                    <button (click)="invoiceDetails()" class="!uppercase block mx-auto text-xl font-bold text-white text-center">{{ 'navbar.invoice-details' | translate }}</button>
                </li>
                <li class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                    <button (click)="showResetPasswordModal()" class="block uppercase text-xl mx-auto font-bold text-white text-center">{{ 'navbar.change-password' | translate }}</button>
                </li>
            </ng-container>
            <li class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                <div class="flex justify-center">
                    <span 
                        class="cursor-pointer px-4 hover:font-black uppercase text-[rgba(255,255,255,.5)] hover:text-[rgba(255,255,255,1)]"
                        *ngFor="let language of availableLanguages; index as index" 
                        (click)="setLanguage(language.slug)" 
                        [ngClass]="{'font-black !text-[rgba(255,255,255,1)]' : isCurrentLanguage(language.slug), 'border-r border-white' : index < availableLanguages?.length! -1}" >
                        {{ language.slug }}
                    </span>
                </div>
            </li>
        </ul>
        <div>
            <ul class="gap-10 flex flex-col">
                <ng-container *ngIf="!isUserLoggedIn()">
                    <li
                        class="relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                        <app-login-or-register></app-login-or-register>
                    </li>
                </ng-container>
                <ng-container *ngIf="isUserLoggedIn()">
                    <li
                        class="inline relative after:absolute after:w-[90%] after:-bottom-[20px] after:left-1/2 after:-translate-x-1/2 after:h-[1px] after:bg-white">
                        <button type="button" class="!uppercase block mx-auto text-xl font-bold text-white text-center"
                            (click)="logout()">{{ 'navbar.logout' | translate }}</button>
                    </li>
                </ng-container>

            </ul>
        </div>
    </div>
</div>
