<svg xmlns="http://www.w3.org/2000/svg" 
    id="stadium-main-svg"  
    [attr.viewBox]="stadiumState.viewBox"
    [ngStyle]="{'background-image': 'url(https://csakjegyek-production-public.s3.eu-central-1.amazonaws.com/clients/' + commonState.client + '/stadium/' + stadiumState.id + '/background2.svg?v=1)'}">
    
    <ng-container  *ngFor="let sector of sectors; index as i; trackBy: identify" >
            <!-- SEATS -->
            <g 
            *ngIf="isSectorSeated(sector) && displaySeatsForSector(sector.i)"
            [attr.transform]="sector.s_t" 
            [attr.name]="sector.n"
            [attr.id]="'sector_' + sector.n"
            >
                <ng-container *ngIf="sector.s">
                    <ng-container *ngFor="let seat of sector.s; trackBy: identifySeat" >
                            <!-- SEAT -->
                            <rect 
                                class="seat"
                                [attr.x]="seat.x" 
                                [attr.y]="seat.y"
                                (click)="seatClicked(seat, sector)"
                                [attr.transform] = "seat.t"
                                [attr.data-title]="getSeatTooltipTitle(seat)"
                                [attr.data-content]="getSeatTooltipContent(seat, sector)"
                                [ngClass]="{
                                    'sold' : isSeatSold(seat.id) || isSectorClosed(sector), 
                                    'cashier-selectable' : isClosedForPublicButSelectableForCashier(seat.id),
                                    'in-my-cart' : isSeatInMyCart(seat.id), 
                                    'is-requesting' : isRequesting[seat.id] || deletingSeats[seat.id]}"
                            ></rect> 
                            <!-- SEAT TEXT -->
                            <text class="seat-text" 
                                [ngClass]="{
                                    'is-requesting' : isRequesting[seat.id] || deletingSeats[seat.id]
                                }" 
                                [attr.data-title]="getSeatTooltipTitle(seat)"
                                [attr.data-content]="getSeatTooltipContent(seat, sector)"
                                (click)="seatClicked(seat, sector)"
                                xmlns="http://www.w3.org/2000/svg"
                                font-family="SFProText-Bold, SF Pro Text" 
                                font-size="5" 
                                font-weight="bold" 
                                letter-spacing="-0.60294125" 
                                fill="#fff">
                                <tspan 
                                    class="seat-text" 
                                    [attr.data-title]="getSeatTooltipTitle(seat)"  
                                    [attr.data-content]="getSeatTooltipContent(seat, sector)" 
                                    [attr.x]="seat.x + 5.5" 
                                    [attr.y]="seat.y + 6.5"
                                    [ngClass]="{
                                        'is-requesting' : isRequesting[seat.id] || deletingSeats[seat.id]
                                    }"
                                >{{ seat.s_n }}</tspan>
                            </text>
                    </ng-container>
                </ng-container>
            </g>
            <!-- SEATS -->

            <!-- SECTORS -->
            <g (click)="handleSectorClick($event, sector)" 
                id="sectors" 
                [attr.transform]="sector.g_t" 
                [attr.name]="sector.n" 
                [ngClass]="{'my-hidden' : scale >= scaleThreshold && displaySeatsForSector(sector.i)}">

                <path class="sector" 
                    [class]="{'disabled' : isSectorClosed(sector)}"
                    [attr.data-title]="sectorTranslatedDataTitle(sector)" 
                    [attr.data-content]="sector.tt?.content"  
                    [attr.transform]="sector.p_t" 
                    [attr.d]="sector.d"
                    [attr.fill]="getSectorFill(sector)">
                </path>
            </g>

            <!-- SEATS LOADING ANIMATION -->
            <ng-container *ngIf="!sector.s">
                <svg xmlns="http://www.w3.org/2000/svg" 
                [attr.x]="sector.t_c.x - 20" 
                [attr.y]="sector.t_c.y - 40" 
                startOffset="50%" 
                text-anchor="middle" 
                alignement-baseline="middle"
                width="40px" 
                height="40px" 
                viewBox="0 0 24 24">
                    <path fill="white" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity="0.25"/>
                    <path fill="white" d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z">
                        <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/>
                    </path>
                </svg>
            </ng-container>
            <!-- SEATS LOADING ANIMATION -->

            <!-- SECTOR TEXT-->
            <text 
                *ngIf="sector.t_c.x && sector.t_c.y"
                font-size="60" 
                class="sector-text cursor-pointer" 
                (click)="handleSectorClick($event, sector)" 
                [ngClass]="{'my-hidden' : scale >= scaleThreshold && displaySeatsForSector(sector.i)}">
                <tspan 
                    [attr.fill]="isSectorClosed(sector) || getNumberOfTicketsInSector(sector.i) ? 'white' : 'black'" 
                    [attr.x]="sector.t_c.x" 
                    [attr.y]="sector.t_c.y" 
                    [attr.data-title]="sectorTranslatedDataTitle(sector)" 
                    [attr.data-content]="sector.tt?.content" 
                    class="sector-text" 
                    startOffset="50%" 
                    text-anchor="middle" 
                    alignement-baseline="middle"
                >
                    {{ sector.n }}
                </tspan>
                <tspan [attr.fill]="isSectorClosed(sector) || getNumberOfTicketsInSector(sector.i) ? 'white' : 'black'" *ngIf="getNumberOfTicketsInSector(sector.i)" [attr.x]="sector.t_c.x" [attr.y]="sector.t_c.y + 60" [attr.data-title]="sectorTranslatedDataTitle(sector)" [attr.data-content]="sector.tt?.content" style="font-size:50px" class="sector-text">
                    {{ getNumberOfTicketsInSector(sector.i) + 'db' }} {{ sectorNumberOfItemsText }}
                </tspan>
            </text>
            <!-- SECTOR TEXT-->
            <!-- SECTORS -->

        </ng-container>
</svg>
