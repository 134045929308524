import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XModule } from 'src/app/components/assets/icons/x/x.module';
import { CartService } from 'src/app/services/Cart/cart.service';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { PurchaseType } from 'src/app/enums/stadium/purchase-type';
import { BookSeatData } from 'src/app/interfaces/State/Seat/book-seat-data';
import { FlexTicket } from 'src/app/interfaces/State/CartItem/flex-ticket';
import { Tickets } from 'src/app/interfaces/State/CartItem/tickets';

@Component({
  selector: 'app-flex-ticket',
  standalone: true,
  imports: [CommonModule, XModule],
  templateUrl: './flex-ticket.component.html',
  styleUrls: ['./flex-ticket.component.scss']
})
export class FlexTicketComponent {
  @Input() flexTicket: Tickets = {} as Tickets
  @Input() fixtureIds: Array<string> = []
  @Input() cart: Cart = {} as Cart
  @Input() key!: number
  @Input() flexUuid?: string

  public isRequesting: boolean = false

  constructor(
    private _cartService: CartService,
    private _alertService: AlertService,
    private _modalService: ModalService,
  ) {
  }

  showPersonalDetailModal() {
    this._modalService.showFlexTicketPersonalDetails({
      flexTicketId: this.flexTicket.seat_id, 
      flexRuleUuid: this.flexUuid,
      key: this.key,
      flexTicketUuid: this.flexTicket.uuid,
    })
  }

  showFlexTicketTypeEditModal() {
    this._modalService.showFlexTicketTypeEditModal({
      flexTicket: this.flexTicket, 
      flexFixtureIds: this.fixtureIds, 
      flexRuleUuid: this.flexUuid
    })
  }

  async removeSeat(flexTicketPurchaseUuid: string): Promise<void> {
    if (!flexTicketPurchaseUuid) return

    this.isRequesting = true

    const data = {
      selectedFixtures: this.fixtureIds,
    }

    const res = await this._cartService.removeSeat(flexTicketPurchaseUuid, this.flexUuid!, PurchaseType.FLEX, data as BookSeatData)
    
    this.isRequesting = false

    /* handle error */
    if (res.error) {
      this._alertService.error('Hiba történt!', res.error.error ?? 'A foglalás törlése sikertelen!')
      return 
    }
  }
}
