import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { Store, StoreModule } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { setCart } from './actions/cart.actions';
import { setClient } from './actions/common.actions';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CartInterceptor } from './interceptors/Cart/cart.interceptor';
import { ClientInterceptor } from './interceptors/Client/client.interceptor';
import { LanguageInterceptor } from './interceptors/Language/language.interceptor';
import { CommonState } from './interfaces/State/Common/common-state';
import { DefaultModule } from './layouts/default/default.module';
import { IndexModule as FixtureIndexModule } from './pages/fixture/index/index.module';
import { IndexModule as SeasonPassIndexModule } from './pages/season-pass/index/index.module';
import { StadiumModule } from './pages/stadium/stadium.module';
import { CartReducer } from './reducers/cart.reducer';
import { CommonReducer } from './reducers/common.reducer';
import { FixtureReducer } from './reducers/fixture.reducer';
import { SeatsReducer } from './reducers/seats.reducer';
import { StadiumReducer } from './reducers/stadium.reducer';
import { ApiRequestService } from './services/ApiRequest/api-request.service';
import { ModalReducer } from './reducers/modal.reducer';
import { AuthReducer } from './reducers/auth.reducer';
import { CookieService } from './services/Cookie/cookie.service';
import { login, logout, setUser } from './actions/auth.actions';
import { AuthInterceptor } from './interceptors/Auth/auth.interceptor';
import { HomescreenModule } from './pages/homescreen/homescreen.module';
import { CartModule } from './pages/cart/cart.module';
import { User } from './interfaces/State/User/user';
import { UnauthenticatedInterceptor } from './interceptors/Unauthenticated/unauthenticated.interceptor';
import { OrderResultModule } from './pages/order-result/order-result.module';
import { OrderReducer } from './reducers/order.reducer';
import { CartService } from './services/Cart/cart.service';
import { MyTicketReducer } from './reducers/myticket.reducer';
import { MyTicketsModule } from './pages/my-tickets/my-tickets.module';
import { HomepageReducer } from './reducers/homepage.reducer';
import { SeasonPassReducer } from './reducers/season-pass.reducer';
import { MySeasonPassComponent } from './components/my-season-pass/my-season-pass.component';
import { MySeasonPassReducer } from './reducers/myseasonpass.reducer';
import { MySeasonPassesModule } from './pages/my-season-passes/my-season-passes.module';
import { SectorReducer } from './reducers/sector.reducer';
import { GameModule } from './pages/game/game.module';
import { GameQuizComponent } from './components/game/game-quiz/game-quiz.component';
import { TabsHolderComponent } from './components/common/tabs-holder/tabs-holder.component';
import * as Sentry from "@sentry/angular";
import { RetryInterceptor } from './interceptors/Retry/retry.interceptor';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SpecialAccessCodeInterceptor } from './interceptors/SpecialAccessCode/special-access-code.interceptor';
import { FlexReducer } from './reducers/flex.reducer';

/* setting client by hostname */
function initializeClient(router: Router, store: Store<CommonState>): Function {
  return () => new Promise<void>((resolve) => {
    const client = environment.environment == 'development' ? environment.client : window.location.hostname.split('.')[0]
    store.dispatch(setClient({client: client}))
    resolve()
  })
}

/* initialize cart */
function initializeCart(router: Router, store: Store<CommonState>, cartService: CartService): Function {
  return () => new Promise<void>(async (resolve) => {
    await cartService.getCart()
    resolve()
  })
}

/* initialize user */
function initializeUser(router: Router, store: Store<CommonState>, apiRequestService: ApiRequestService, cookieService: CookieService): Function {
  return () => new Promise<void>(async (resolve) => {
    const token = cookieService.getCookie('access_token')
    /* if token exists */
    if(token) {
      try {
        const res = await apiRequestService.getRequest('me')
        store.dispatch(login())
        store.dispatch(setUser({user: {...res.data as User}}))
      } catch (e) {
        store.dispatch(logout())
        store.dispatch(setUser({ user: {} as User }))
      }
    }
    resolve()
  })
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    StadiumModule,
    FixtureIndexModule,
    SeasonPassIndexModule,
    DefaultModule,
    HomescreenModule,
    HttpClientModule, 
    CartModule,
    OrderResultModule,
    MyTicketsModule,
    MySeasonPassesModule,
    GameModule,
    StoreModule.forRoot({
      stadium: StadiumReducer, 
      common: CommonReducer,
      fixtures: FixtureReducer,
      seats: SeatsReducer, 
      cart: CartReducer,
      modals: ModalReducer,
      auth: AuthReducer,
      order: OrderReducer,
      myTickets: MyTicketReducer,
      homepage: HomepageReducer,
      seasonPasses: SeasonPassReducer, 
      mySeasonPasses: MySeasonPassReducer,
      sector: SectorReducer,
      flex: FlexReducer,
    }),
    TranslateModule.forRoot({
      //defaultLanguage: 'en',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: ClientInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CartInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpecialAccessCodeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthenticatedInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initializeUser, deps: [Router, Store, ApiRequestService, CookieService], multi: true},
    { provide: APP_INITIALIZER, useFactory: initializeClient, deps: [Router, Store], multi: true},
    { provide: APP_INITIALIZER, useFactory: initializeCart, deps: [Router, Store, CartService], multi: true},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false, 
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
