<li class="p-5 relative border-b border-b-[#d0d0d0]" *ngIf="ticket">
    <button 
        type="button" 
        [ngClass]="{'cursor-progress opacity-30' : isRequesting}" 
        class="absolute top-4 right-3 outline-none appearance-none border-0" 
        (click)="removeSeat(ticket.id, ticket.eventId)">
        <app-x></app-x>
    </button>
    <a [routerLink]="['/fixture', ticket.eventId] | localize" class="text-xs" *ngIf="ticket.eventId != currentFixtureId">{{ ticket.event }}</a>   
    <p class="text-sm font-black">{{ ticket.title }}</p>
    <p class="text-sm">{{ ticket.priceText }}</p>
    <div class="text-[13px]">
        <span>{{ ticket.type }}</span>
        <ng-container *ngIf="ticket.sectorId">
            /
            <button class="font-bold text-[#d4013f]" (click)="editTicket()">{{ 'general.edit' | translate }}</button>
        </ng-container>
    </div>
</li>
<li class="p-5 relative border-b border-b-[#d0d0d0]" *ngIf="seasonPass">
    <button 
        type="button" 
        [ngClass]="{'cursor-progress opacity-30' : isRequesting}" 
        class="absolute top-4 right-3 outline-none appearance-none border-0" 
        (click)="removeSeasonPass(seasonPass.id, seasonPass.seasonPassSlug)">
        <app-x></app-x>
    </button>
    <a
        [routerLink]="['/season-pass', seasonPass.seasonPassSlug] | localize" 
        class="text-xs" 
        *ngIf="seasonPass.seasonPassSlug != currentSeasonPassSlug">{{ seasonPass.seasonPass }}</a>   
    <a class="text-xs" *ngIf="seasonPass.seasonPassSlug == currentSeasonPassSlug">{{ seasonPass.seasonPass }}</a>   
    <p class="text-sm font-black">{{ seasonPass.title }}</p>
    <p class="text-sm">{{ seasonPass.priceText }}</p>
    <div class="text-[13px]">
        <span>{{ seasonPass.type }}</span>
        <ng-container *ngIf="seasonPass.sectorId">
            /
            <button class="font-bold text-[#d4013f]" (click)="editSeasonPass()">{{ 'general.edit' | translate }}</button>
        </ng-container>
    </div>
</li>
<li class="p-5 relative border-b border-b-[#d0d0d0]" *ngIf="flexTicket">
    <button 
        type="button" 
        [ngClass]="{'cursor-progress opacity-30' : isRequesting}" 
        class="absolute top-4 right-3 outline-none appearance-none border-0" 
        (click)="removeFlex(flexTicket.uuid, flexTicket.flex.uuid)">
        <app-x></app-x>
    </button>
    <a [routerLink]="'/flex/' + flexTicket.flex.uuid" [queryParams]="{'fixtures[]' : flexTicket.fixture_ids}" class="text-xs" *ngIf="flexTicket.flex.uuid != currentFlexUuid">{{ flexTicket.flex.title }}</a>   
    <p class="text-sm font-black">{{ flexTicket.title }}</p>
    <p class="text-sm">{{ flexTicket.priceText }}</p>
    <div class="text-[13px]">
        <span>{{ flexTicket.type }}</span>
        <ng-container *ngIf="flexTicket.sectorId">
            /
            <button class="font-bold text-[#d4013f]" (click)="editFlexTicket()">Módosítás</button>
        </ng-container>
    </div>
</li>