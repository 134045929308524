import { Component, Input } from '@angular/core';
import { Index } from 'src/app/interfaces/State/Fixture';
import { Index as SeasonPassIndex } from 'src/app/interfaces/State/SeasonPass';
import { Location } from '@angular/common'
import { Router } from '@angular/router';
import { FixtureType } from 'src/app/enums/fixture/fixtureType';
import { FlexRule } from 'src/app/interfaces/State/Flex/FlexRule';

@Component({
  selector: 'app-infobar',
  templateUrl: './infobar.component.html',
  styleUrls: ['./infobar.component.scss']
})
export class InfobarComponent {
  @Input() openedFixture: Index = {} as Index
  @Input() mapOnly: Boolean = false
  @Input() openedSeasonPass: SeasonPassIndex = {} as SeasonPassIndex
  @Input() openedFlex: FlexRule = {} as FlexRule

  constructor(
    private location: Location,
    private _router: Router,
  ) {}

  navigateBack() {
    this.location.back()
  }

  public fixtureTypes() {
    return FixtureType
  }

  public isFixtureTypes(fixture: any, eventType: string) {
    return eventType == fixture.event_type
  }
}
