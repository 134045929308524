<div class="p-6">  
    <a (click)="navigateBack()" class="cursor-pointer flex gap-2 items-center text-white font-bold"><app-back-icon/>{{ 'e-ticket.back-to-tickets' | translate }}</a>
    
    <app-skeleton-loader [row]="26.125" *ngIf="isRequesting && !eSeasonPass?.season_pass && !eTicket?.fixture" classList="rounded-[10px] mt-8" />

    <div *ngIf="eTicket?.ticket?.has_pending_transfer" class="bg-white rounded-xl p-6 mt-8">
        <p class="text-center break-words" [innerHTML]="'e-ticket.ticket-already-transfered' | translate:{email:eTicket?.ticket?.pending_transfers?.email}"></p>
    </div>

    <div *ngIf="eSeasonPass?.ticket?.has_pending_transfer" class="bg-white rounded-xl p-6 mt-8">
        <p class="text-center break-words" [innerHTML]="'e-ticket.season-pass-already-transfered' | translate:{email:eSeasonPass?.ticket?.pending_transfers?.email}"></p>
    </div>

    <div *ngIf="topError" class="bg-white rounded-xl p-6 mt-8">
        <p class="text-center break-words font-bold text-[var(--red)]" [innerHTML]="topError"></p>
    </div>

    <div *ngIf="!eTicket?.ticket?.has_pending_transfer && !eSeasonPass?.ticket?.has_pending_transfer">
        <!-- EVENT INFORMATIONS -->
        <div class="mt-8" *ngIf="false && eTicket?.fixture">
            <!-- FIXTURE -->
            <a [routerLink]="'/merkozes/' + eTicket?.fixture?.slug" *ngIf="isFixtureTypes(eTicket?.fixture, fixtureTypes().MATCH)" class="flex justify-between select-none items-center text-white  sm:w-full min-w-[500px] max-w-[50%] sm:max-w-full sm:min-w-0">
                <img class="h-[40px]" [src]="eTicket?.fixture?.home_team_logo" [alt]="eTicket?.fixture?.teams"/>
                <div class="px-2">
                    <p class="text-[15px] font-black text-center">{{ eTicket?.fixture?.teams }}</p>
                    <p class="text-sm text-center ">{{ eTicket?.fixture?.date }} ({{ eTicket?.fixture?.stadium}})</p>
                </div>
                <img class="h-[40px]" [src]="eTicket?.fixture?.away_team_logo" [alt]="eTicket?.fixture?.teams"/>
            </a>
            <!-- OTHER EVENT -->
            <div *ngIf="isFixtureTypes(eTicket?.fixture, fixtureTypes().OTHER)" class="flex gap-3 items-center text-white  sm:w-full min-w-[500px] max-w-[50%] sm:max-w-full sm:min-w-0">
                <img class="h-[40px]" *ngIf="eTicket?.fixture?.image" [src]="eTicket?.fixture?.image" [alt]="eTicket?.fixture?.name"/>
                <div >
                    <p class="text-[15px] font-black text-left">{{ eTicket?.fixture?.name}}</p>
                    <p class="text-sm text-center ">{{ eTicket?.fixture?.date }} ({{ eTicket?.fixture?.stadium}})</p>
                </div>
            </div>
        </div>

         <!-- SEASON PASS -->
        <div class="mt-8" *ngIf="false && eSeasonPass?.season_pass">
            <div class="flex gap-3 items-center justify-center text-white sm:w-full min-w-[500px] max-w-[50%] sm:max-w-full sm:min-w-0">
                <div >
                    <p class="text-[15px] font-black text-center">{{ eSeasonPass?.season_pass?.name}}</p>
                    <p class="text-sm text-center ">{{ eSeasonPass?.season_pass?.subtitle }}</p>
                </div>
            </div>
        </div>

        <!-- SEASON PASS INFORMATIONS -->
        <div class="bg-white rounded-xl p-6 mt-8 relative
         before:absolute before:bg-[var(--primary-color)] before:w-[30px] before:h-[30px] before:rounded-full before:left-0 before:top-[66px] before:-translate-x-1/2
         after:absolute after:bg-[var(--primary-color)] after:w-[30px] after:h-[30px] after:rounded-full after:right-0 after:top-[66px] after:translate-x-1/2"
        *ngIf="eSeasonPass?.season_pass && eSeasonPass?.ticket">
        <p class="text-center font-bold mb-[20px]">{{ eSeasonPass?.season_pass?.name }} - {{ eSeasonPass?.ticket?.type }}</p>

            <div class="flex justify-center" [innerHTML]="printIdentifier(eSeasonPass!.ticket!)"></div>
            <div class="text-center text-xs mt-[3px]">{{ eSeasonPass?.ticket?.identifier }}</div>

            <p class="font-bold text-center text-lg mt-[20px]">{{ eSeasonPass?.ticket?.title }}</p>
            <p class="text-center text-sm text-gray-400">{{ eSeasonPass?.ticket?.personal_details?.full }}</p>


            <p class="mt-10"><strong>{{ 'e-ticket.price' | translate }}:</strong> {{ eSeasonPass?.ticket?.price }}</p>
            <p><strong>{{ 'e-ticket.purchased_at' | translate }}:</strong> {{ eSeasonPass?.order?.created_at }}</p>

            <p class="mb-4">{{ 'e-ticket.vat' | translate }}</p>

            <p class="mb-4">{{ eSeasonPass?.settings?.['rules_hu'] }}</p>
            <p class="mb-4">{{ eSeasonPass?.settings?.['rules_en'] }}</p>
            <p>{{ eSeasonPass?.settings?.['rules_de'] }}</p>
        </div>


        <!-- TICKET INFORMATIONS -->
        <div class="rounded-xl p-6 mt-8 relative
        bg-cover
         before:absolute before:bg-[var(--primary-color)] before:w-[30px] before:h-[30px] before:rounded-full before:left-0 before:top-[66px] before:-translate-x-1/2
         after:absolute after:bg-[var(--primary-color)] after:w-[30px] after:h-[30px] after:rounded-full after:right-0 after:top-[66px] after:translate-x-1/2"
        *ngIf="eTicket?.fixture && eTicket?.ticket"
        [style]="'background-color:' + eTicket?.ticket?.background_colour + ';background-image: url(\'' + eTicket?.ticket?.background_image + '\')' "
        >
            <div *ngIf="eTicket?.ticket?.flex?.is_flex" class="flex justify-center">
                <p class="hidden sm:inline-block mx-auto bg-[var(--red)] text-white font-bold rounded-[10px] px-3 py-1 mb-3 text-sm"
                [style]="'color:' + eTicket?.ticket?.text_colour"
                >{{ eTicket?.ticket?.flex?.title }}</p>
            </div>

            <p class="text-center font-bold mb-[20px]" [style]="'color:' + eTicket?.ticket?.text_colour">{{ eTicket?.fixture?.competition }} - {{ eTicket?.ticket?.type }}</p>
            <div class="text-center">
                <div class="justify-center bg-white p-[10px] rounded-[10px] inline-block" [innerHTML]="printIdentifier(eTicket!.ticket!)"></div>
            </div>
            <div class="text-center text-xs mt-[5px] font-bold" [style]="'color:' + eTicket?.ticket?.text_colour">{{ eTicket?.ticket?.identifier }}</div>
            
            <img *ngIf="eTicket?.ticket?.logo" [src]="eTicket?.ticket?.logo" class="max-w-[90%] mx-auto mt-4">

            <p class="font-bold text-center text-lg mt-[20px]" [style]="'color:' + eTicket?.ticket?.text_colour">{{ eTicket?.ticket?.title }}</p>
            <p class="text-center text-sm" [style]="'color:' + eTicket?.ticket?.text_colour">{{ eTicket?.ticket?.personal_details?.full }}</p>

            <hr class="mt-[20px]" [style]="'border-color:' + eTicket?.ticket?.text_colour "/>

            <p class="mt-10" [style]="'color:' + eTicket?.ticket?.text_colour"><strong>{{ 'e-ticket.price' | translate }}:</strong> {{ eTicket?.ticket?.price }}</p>
            <p [style]="'color:' + eTicket?.ticket?.text_colour"><strong>{{ 'e-ticket.gate-opening' | translate }}:</strong> {{ eTicket?.fixture?.gate_opening_time }}</p>
            <p [style]="'color:' + eTicket?.ticket?.text_colour"><strong>{{ 'e-ticket.gate' | translate }}:</strong> {{ eTicket?.ticket?.gate_name }}</p>
            <p [style]="'color:' + eTicket?.ticket?.text_colour"><strong>{{ 'e-ticket.purchased_at' | translate }}:</strong> {{ eTicket?.order?.created_at }}</p>

            <p class="mb-4" [style]="'color:' + eTicket?.ticket?.text_colour">{{ 'e-ticket.vat' | translate }}</p>

            <p class="mb-4" [style]="'color:' + eTicket?.ticket?.text_colour">{{ eTicket?.settings?.['rules_hu'] }}</p>
            <p class="mb-4" [style]="'color:' + eTicket?.ticket?.text_colour">{{ eTicket?.settings?.['rules_en'] }}</p>
            <p [style]="'color:' + eTicket?.ticket?.text_colour">{{ eTicket?.settings?.['rules_de'] }}</p>
        </div>
        <!-- TICKET INFORMATIONS -->

        <!-- TICKET PERSONAL DETAILS EDIT -->
        <div class="bg-white rounded-xl p-6 mt-8 relative" *ngIf="eTicket?.fixture && eTicket?.ticket && eTicket?.fixture?.can_update_tickets_personal_details">
            <p class="text-left font-bold mb-[20px]">Jegytulajdonos adatainak módosítása</p>
            <p class="text-gray-400 mb-3 text-sm">Módosítsd egyszerűen a jegy tulajdonosának adatait az alábbi gombra katitintva!</p>

            <p *ngIf="error && !success" class="text-[var(--red)] text-center mb-8 break-words" [innerHTML]="error"></p>

            <div class="mt-4">
                <app-button [isProcessing] = "isTransfering" (click)="showPersonalDetails()">Adatok módosítása</app-button>
            </div>
        </div>
        <!-- TICKET PERSONAL DETAILS EDIT -->

        <!-- TICKET TRANSFER -->
        <div class="bg-white rounded-xl p-6 mt-8 relative" *ngIf="false && !success && ((eTicket?.fixture && eTicket?.ticket && eTicket?.ticket?.is_transfer_allowed) || (eSeasonPass?.season_pass && eSeasonPass?.ticket && eSeasonPass?.ticket?.is_transfer_allowed))">
            <p class="text-left font-bold mb-[20px]">{{  eTicket?.fixture ? ('e-ticket.send-ticket' | translate) : ('e-ticket.send-season-pass' | translate) }}</p>
            <p class="text-gray-400 mb-3">{{ eTicket?.fixture ? ('e-ticket.send-ticket-to-owner' | translate) : ('e-ticket.send-season-pass-to-owner' | translate) }}</p>

            <p *ngIf="error && !success" class="text-[var(--red)] text-center mb-8 break-words" [innerHTML]="error"></p>

            <form [formGroup]="transferForm" (ngSubmit)="transfer()" [ngClass]="{'is-requesting' : isTransfering}" >
                <ng-container *ngIf="!success">
                    <app-input 
                        [customCssClasses]="isFieldInvalidAtAll('email') ? 'border border-[var(--red)]' : ''" 
                        [placeholder]="'validation.email-address' | translate" 
                        (inputValueChangedEvent)="setValue('email', $event)"></app-input>
                    
                    <app-input-error-msg 
                        customCssClasses="pl-4" 
                        [show]="isFieldInvalid('email', 'required')" 
                        [errorMsg]="'validation.email-address-required' | translate">                
                    </app-input-error-msg>
                    
                    <app-input-error-msg 
                        customCssClasses="pl-4" 
                        [show]="isFieldInvalid('email', 'email')" 
                        [errorMsg]="'validation.email-address-invalid' | translate">
                    </app-input-error-msg>
        
                    <div class="my-5">
                        <app-checkbox 
                            (inputValueChangedEvent)="setValue('terms', $event)" 
                            identifier="terms" 
                            [label]="'e-ticket.transfer-terms-accepted' | translate">
                        </app-checkbox>

                        <app-input-error-msg 
                            customCssClasses="pl-4" 
                            [show]="isFieldInvalid('terms', 'required')" 
                            [errorMsg]="'validation.accepting-terms-is-required' | translate">
                        </app-input-error-msg>
                    </div>

                    <div class="mt-4">
                        <app-button 
                            [isProcessing] = "isTransfering">
                            {{ eTicket?.fixture ? ('e-ticket.send-ticket' | translate) : ('e-ticket.send-season-pass' | translate) }}
                        </app-button>
                    </div>
                </ng-container>
            </form>
        </div>  
        <!-- TICKET TRANSFER -->
    </div>

    
</div>
