import { createReducer, on } from "@ngrx/store";
import { setOpenedFixture, setUpcomingFixtures } from "../actions/fixture.actions";
import { FlexRule } from "../interfaces/State/Flex/FlexRule";
import { StadiumFlex } from "../interfaces/State/Flex/StadiumFlex";
import { setOpenedFlex } from "../actions/flex.actions";

export const initialState = {
    openedFlex:{} as FlexRule,
} as StadiumFlex

export const FlexReducer = createReducer(
    initialState,
    on(setOpenedFlex, (state, {flex}) => ({...state, openedFlex : flex})),
);