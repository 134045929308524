<li  class="grid grid-cols-[30%_50%_20%] sm:flex sm:flex-col p-5 relative border-t border-t-[#d0d0d0]">
    <button type="button" [ngClass]="{'cursor-progress opacity-30' : isRequesting}" class="absolute top-6 right-10 outline-none appearance-none border-0" (click)="removeSeat(flexTicket.uuid!)">
        <app-x></app-x>   
    </button>
    <div>
        <p class="text-sm font-bold">{{ flexTicket.title }}</p>
        <p class="text-[13px]">{{ flexTicket.price }}</p>
        <p class="text-[13px] whitespace-nowrap">{{ flexTicket.type }} / <button type="button" (click)="showFlexTicketTypeEditModal()" class="font-bold text-[var(--red)]">Jegytípus módosítása</button></p>
    </div>
    <div class="flex justify-center items-center sm:mt-3" *ngIf="!flexTicket.details.personal_details.full">   
        <button *ngIf="flexTicket.requirements.personal_details.length" type="button" class="w-full font-bold text-[13px] text-[var(--red)]" (click)="showPersonalDetailModal()">
            {{ flexTicket.need_to_fill_details ? 'Jegytulajdonos kitöltése kötelező!' : 'Jegytulajdonos megadása' }}
        </button>
    </div>
    <div class="flex justify-between items-center sm:mt-3" *ngIf="flexTicket.details.personal_details.full">   
        <p class="text-[13px]">{{ flexTicket.details.personal_details.short }}</p>
        <button type="button" class="font-bold text-[13px] text-[var(--red)]" (click)="showPersonalDetailModal()">Jegytulajdonos módosítása</button>
    </div>
</li>
