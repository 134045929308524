import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { hideModal, showModal } from 'src/app/actions/modal.actions';
import { ModalType } from 'src/app/enums/modals/modalType';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private _store: Store,
  ) { }

  public showLoginModal() {
    this._store.dispatch(showModal({wrapperCssClasses: 'dark-bg', modalType: ModalType.LOGIN}))
  }

  public showInvoiceModal() {
    this._store.dispatch(showModal({wrapperCssClasses: 'dark-bg', modalType: ModalType.USER_INVOICE_DETAILS}))
  }

  public showCartInvoiceModal() {
    this._store.dispatch(showModal({wrapperCssClasses: 'dark-bg', modalType: ModalType.CART_INVOICE_DETAILS}))
  }

  public showCartTicketPersonalDetails(data: any) {
    this._store.dispatch(showModal({wrapperCssClasses: 'dark-bg', modalType: ModalType.CART_TICKET_PERSONAL_DETAILS, data : data}))
  }

  public showCartSeasonPassPersonalDetails(data: any) {
    this._store.dispatch(showModal({wrapperCssClasses: 'dark-bg', modalType: ModalType.CART_TICKET_PERSONAL_DETAILS, data : data}))
  }

  public showFlexTicketPersonalDetails(data: any) {
    this._store.dispatch(showModal({wrapperCssClasses: 'dark-bg', modalType: ModalType.CART_TICKET_PERSONAL_DETAILS, data : data}))
  }

  public showTicketTypeEditModal(data: any) {
    this._store.dispatch(showModal({wrapperCssClasses: 'dark-bg', modalType: ModalType.TICKET_TYPE_EDIT, data: data}));
  }

  public showSeasonPassTicketTypeEditModal(data: any) {
    this._store.dispatch(showModal({wrapperCssClasses: 'dark-bg', modalType: ModalType.TICKET_TYPE_EDIT, data: data}));
  }

  public showFlexTicketTypeEditModal(data: any) {
    this._store.dispatch(showModal({wrapperCssClasses: 'dark-bg', modalType: ModalType.TICKET_TYPE_EDIT, data: data}));
  }

  public hideModal() {
    this._store.dispatch(hideModal());
  }

  public showStandingSectorTicketSelectorModal(data: any) {
    this._store.dispatch(showModal({wrapperCssClasses: 'dark-bg', modalType: ModalType.STANDING_SECTOR_TICKET_SELECTOR, data: data}));
  }

  public showSpecialAccessModal(data: any) {
    this._store.dispatch(showModal({wrapperCssClasses: 'dark-bg', modalType: ModalType.SPECIAL_ACCESS_MODAL, data: data}));
  }

}
