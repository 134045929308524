import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { FlexTicket } from 'src/app/interfaces/State/CartItem/flex-ticket';
import { SeasonPasses } from 'src/app/interfaces/State/CartItem/season-passes';
import { Ticket } from 'src/app/interfaces/State/CartItem/ticket';
import { Tickets } from 'src/app/interfaces/State/CartItem/tickets';
import { Modals } from 'src/app/interfaces/State/Modal/modals';
import { SectorDetail } from 'src/app/interfaces/State/Stadium/sector-detail';
import { StadiumWithSectorsAndSeats } from 'src/app/interfaces/State/Stadium/stadium-with-sectors-and-seats';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartService } from 'src/app/services/Cart/cart.service';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';

@Component({
  selector: 'app-edit-ticket-type',
  templateUrl: './edit-ticket-type.component.html',
  styleUrls: ['./edit-ticket-type.component.scss']
})
export class EditTicketTypeComponent {
  public formSubmitAttempt: boolean = false
  public isRequesting?: number
  public error: string = ""
  public cart: Cart = {} as Cart
  public personalDetailsForm  = this._formBuilder.group({}); 
  public modals: Modals = {} as Modals  
  public ticket: Tickets = {} as Tickets
  public seasonPass: SeasonPasses = {} as SeasonPasses
  private _subscription?: Subscription
  public ticketTypes: Array<SectorDetail> = []
  public stadiumState: StadiumWithSectorsAndSeats = {} as StadiumWithSectorsAndSeats
  public fixtureId: string = ""
  public seasonPassSlug: string = ""
  public flexRuleUuid: string = ""
  public flexTicket: Tickets = {} as Tickets
  public flexFixtureIds: Array<string> = []

  constructor(    
    private _modalService: ModalService,  
    private _store: Store,
    private _formBuilder: FormBuilder,
    private _cartService: CartService,
    private _alertService: AlertService,
    private _translateService: TranslateService,
  ){

  }

  ngOnInit(): void {

    this._subscription = this._store.subscribe((state: any) => {
      this.cart = state.cart
      this.modals = state.modals
      this.ticket = this.modals.modal.data['ticket']
      this.fixtureId = this.modals.modal.data['fixtureId']
      this.seasonPass = this.modals.modal.data['seasonPass']
      this.seasonPassSlug = this.modals.modal.data['seasonPassSlug']
      this.flexRuleUuid = this.modals.modal.data['flexRuleUuid']
      this.flexTicket = this.modals.modal.data['flexTicket']
      this.flexFixtureIds = this.modals.modal.data['flexFixtureIds']

      this.ticketTypes = this.ticket?.types ?? this.seasonPass?.types ?? this.flexTicket.types
    })
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }

  hideModal() {
    this._modalService.hideModal()
  }

  public async selectTicketType(ticketTypeId: number) {
    if(this.isRequesting) return ;

    this.isRequesting = ticketTypeId
    
    let res;
    /* edit ticket type */
    if(this.ticket) {
      res = await this._cartService.updateCartTicketType(this.ticket.seat_id, this.fixtureId, ticketTypeId)
    } 
    /* edit season pass ticket type */
    else if (this.seasonPass) {
      res = await this._cartService.updateSeasonPassTicketType(this.seasonPass.seat_id, this.seasonPassSlug, ticketTypeId)
    } 
    /* edit flex ticket type */
    else if (this.flexTicket) {
      res = await this._cartService.updateFlexTicketType(this.flexTicket.uuid!, this.flexRuleUuid, ticketTypeId)
    }

    this.isRequesting = undefined

    if(res.error) {
      const errorHappened = this._translateService.instant('general.error-happened');
      const editingFailed = this._translateService.instant('general.editing-failed')

      this._alertService.error(errorHappened, res.error.error ?? res.error ?? editingFailed)

      setTimeout(() => {
        this.hideModal()
      }, 500)
      return 
    }    

    if(this.ticket) {
      this.ticket = {
        ...this.ticket,
        type_id: ticketTypeId,
      }
    } else if (this.seasonPass) {
      this.seasonPass = {
        ...this.seasonPass,
        type_id: ticketTypeId,
      }
    } else if (this.flexTicket) {
      this.flexTicket = {
        ...this.flexTicket,
        type_id: ticketTypeId
      }
    }
    
    setTimeout(() => {
      this.hideModal()
    }, 500)
    
  }
}
