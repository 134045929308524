import { Injectable } from '@angular/core';
import { ApiRequestService } from '../ApiRequest/api-request.service';
import { Store } from '@ngrx/store';
import { setStadiumSeats } from 'src/app/actions/stadium.actions';
import { setOpenedFlex } from 'src/app/actions/flex.actions';
import { FlexRule } from 'src/app/interfaces/State/Flex/FlexRule';
import { setSeatDetails, setSoldSeats } from 'src/app/actions/seats.actions';
import { FlexComponent } from 'src/app/pages/flex/flex.component';
import { CookieService } from '../Cookie/cookie.service';
import { SeatDetails } from 'src/app/interfaces/State/Seat/seat-details';
import { SoldSeats } from 'src/app/interfaces/State/Seat/sold-seats';

@Injectable({
  providedIn: 'root'
})
export class FlexService {

  constructor(
    private _apiRequestService: ApiRequestService,
    private _store: Store,
  ) { }

  async getFlex() 
  {
    return await this._apiRequestService.getRequest('flex')
  }

  public async validateSelection(flexId: string, selectedFixtureSlugs: Array<string>)
  {
    return await this._apiRequestService.postRequest('flex/' + flexId + '/validate', {selected_fixture_slugs: selectedFixtureSlugs})
  }

  public async getSingleFlex(flexUuid: string, selectedFixtures: Array<string>) {
    try {
      let res = await this._apiRequestService.postRequest('flex/' + flexUuid + '?seats=false', {selected_fixtures: selectedFixtures})

      this._store.dispatch(setStadiumSeats({ stadium: res.data.stadium }))
      this._store.dispatch(setOpenedFlex({flex: res.data.flex }))

      this.getFlexWithSeats(flexUuid, selectedFixtures)

    } catch (e: any) {
      throw e
    }
  }

  public removeStates() {
    this._store.dispatch(setStadiumSeats({stadium: { name: '', sectors: [] , id : 0, viewBox: ''}}))   
    this._store.dispatch(setOpenedFlex({flex: {} as FlexRule}));
    this._store.dispatch(setSeatDetails({seatDetails: {} as SeatDetails}));
    this._store.dispatch(setSoldSeats({soldSeats: {} as SoldSeats}));
  }

  public async getFlexWithSeats(flexUuid: string,  selectedFixtures: Array<string>) {
    try {
      let res = await this._apiRequestService.postRequest('flex/' + flexUuid,  {selected_fixtures: selectedFixtures})

      this._store.dispatch(setStadiumSeats({ stadium: res.data.stadium }))
      this._store.dispatch(setSeatDetails({ seatDetails: res.data.seat_details }));

    } catch (e: any) {
      throw e
    }
  }
}
