<div id="loader-holder" *ngIf="isLoadingInitially">
    <div class="loader"></div>
</div>
<div
    class="h-[240px] flex flex-col justify-center bg-cover bg-[url('https://png.pngtree.com/thumb_back/fh260/background/20230525/pngtree-green-field-inside-football-stadium-in-silhouette-under-sky-image_2628209.jpg')]">
    <div class="container">
        <div>
            <h1 class="text-white uppercase font-bold text-4xl">Szpari flex</h1>
            <h2 class="text-white font-black mt-1">Állítsd össze saját, kedvezményes bérletedet!</h2>
        </div>
    </div>
</div>
<div class="container !mb-24">
    <div class="flex justify-between mt-10 sm-md:flex-col">
        <div class="w-[60%] sm-md:w-full">
            <h2 class="text-white font-bold mb-6">Mérkőzések</h2>
            <p class="text-white font-bold" *ngIf="!flexFixtures?.length || !flexRules?.length">Nincs elérhető mérkőzés!</p>
            <ul class="flex flex-col gap-8" *ngIf="flexFixtures?.length && flexRules?.length">
                <ng-container *ngFor="let fixture of flexFixtures; let i = index;">
                    <li *ngIf="fixture?.fixture" class="bg-white rounded-[10px] p-6 flex justify-between gap-10 sm-md:flex-col sm-md:gap-5 relative">
                        <!-- LABEL -->
                        <div 
                            [ngClass]="{'!bg-[var(--red)]' : isPremium(fixture)}"
                            class="absolute left-0 -top-[15px] bg-gray-400 px-6 py-1 font-bold text-xs text-white rounded-t-[10px] rounded-br-[10px]">
                            {{ fixture.category_text }}
                        </div>
                         <!-- LABEL -->

                        <!-- FIXTURE -->
                        <div class="flex justify-between items-center w-full">
                            <img class="w-10 h-10 object-contain" [src]="fixture?.fixture?.home_team?.logo" [alt]="fixture?.fixture?.home_team?.name">

                            <div class="flex flex-col gap-0">
                                <p class="text-[15px] font-black text-center px-2">{{ fixture.fixture.teams }}</p>
                                <p class="text-[13px] text-center">{{ fixture.fixture.date }}</p>
                            </div>

                            <img class="w-10 h-10 object-contain" [src]="fixture?.fixture?.away_team?.logo" [alt]="fixture?.fixture?.away_team?.name">
                        </div>
                        <!-- FIXTURE -->

                        <!-- ACTION BUTTON -->
                        <app-button 
                            type="button" 
                            [style]="!isSelected(fixture.fixture.id!) ? 'details' : ''" 
                            classList="!w-[200px] sm-md:!w-full border-red border-[1px]" 
                            [isProcessing]="isRequesting" 
                            (click)="isSelected(fixture?.fixture?.id!) ? unselect(fixture?.fixture?.id!) : select(fixture)">
                            {{ !isSelected(fixture?.fixture?.id!) ? 'Hozzáadás' : 'Kiválasztva' }}</app-button>
                        <!-- ACTION BUTTON -->
                    </li>
                </ng-container>
            </ul>
        </div>
        <div class="w-[35%] sm-md:w-full sm-md:mt-4"  *ngIf="flexFixtures?.length && flexRules?.length">
            <h2 class="text-white font-bold mb-6">Flex szabályok</h2>

            <div class="rounded-[10px] bg-white p-7">
                <ul class="flex flex-col gap-4">
                    <li class="flex gap-4 items-center" *ngFor="let rule of flexRules; let i = index;">
                        <div class="w-[35px] aspect-square overflow-hidden rounded-full">
                            <span *ngIf="lessSelectedFixtures(rule)" class="bg-gray-100 w-full aspect-square block"></span>
                            <span *ngIf="validFixtureSelection(rule)" class="bg-[var(--green)] w-full aspect-square block"></span>
                            <span *ngIf="invalidFixtureSelection(rule)" class="bg-[var(--red)] w-full aspect-square block"></span>
                        </div>
                        <div>
                            <h3 class="font-bold text-sm">{{ rule.title }}</h3>
                            <p class="text-xs text-gray-500">{{ rule.description }}</p>
                        </div>
                    </li>
                </ul>

                <hr class="my-5" />
                <p class="text-[var(--red)] font-bold text-sm">Kiválasztott mérkőzések:</p>

                <ul class="my-3" id="selected-matches" *ngIf="selectedFixtures.length">
                    <li class="inline-block" *ngFor="let fixture of selectedFixtures">
                        <img class="w-10 h-10 object-contain" [src]="fixture?.fixture?.opponent_logo">
                    </li>
                </ul>

                <p *ngIf="!selectedFixtures.length" class="mt-3 mb-4 text-xs text-gray-400">Válaszd ki az első mérkőzést!</p>

                <app-button 
                    type="button" 
                    [isProcessing]="isRequesting" 
                    (click)="next()"
                    [disabled]="!canBeContinued()"

                >
                    {{ redirecting ? 'Folyamatban...' : 'Tovább' }} </app-button>
            </div>
        </div>
    </div>
</div>